import React from "react"
import PropTypes from "prop-types"

export default function Select({
  className,
  style,
  name = "name",
  options = ["option"],
  value = "value",
  onChange = () => {},
}) {
  const handleChange = event => {
    onChange(event.target.value)
  }
  return (
    <div className={`component select is-small ${className}`} style={style}>
      <select
        data-testid={`${name}Select`}
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <option
            data-testid={`${name}${
              option.charAt(0).toUpperCase() + option.slice(1)
            }Option`}
            key={`${name}:${option}`}
            value={option}
          >
            {`${name}: ${option}`}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
