import React from "react"
import PropTypes from "prop-types"
export default function Notification({ className, text }) {
  return (
    <div
      className={`component notification ${className}`}
      data-testid="notification"
    >
      {text}
    </div>
  )
}

Notification.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}

// ${styles.notification} via identity-obj-proxy
// style={{ visibility: "hidden" }}
