import React from "react"
import { Link } from "react-router-dom"
import styles from "./Home.module.css"

export default function Home(props) {
  return (
    <main className={`component ${styles.home} hero is-large`}>
      <section className={`hero-body ${styles.background}`}>
        <div className="container">
          <h1 className="title">IssueHub</h1>
          <h2 className="subtitle">Let your customers help you to improve</h2>
        </div>
      </section>
      <footer className="hero-footer">
        <div className="has-text-centered">
          <Link className="is-link" to={"/feedback"}>
            Let us know how you feel about IssueHub
          </Link>
        </div>
      </footer>
    </main>
  )
}
