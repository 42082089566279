import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import ProtectedRoute from "./components/ProtectedRoute"
import ErrorBoundary from "./components/ErrorBoundary"
import Header from "./components/Header"
import AuthButton from "./components/AuthButton"
// import Nav from "./components/Nav"
import Home from "./views/Home"
import Login from "./views/Login"
import User from "./views/User"
import Board from "./views/Board"
import Issue from "./views/Issue"
import NewIssue from "./views/NewIssue"
import Feedback from "./views/Feedback"
import "./App.css"

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty("--vh", `${vh}px`)

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
})

function App() {
  if (window.Cypress) {
    // debugger
    // debug sth...
    console.log("CYPRESS IS USED")
  }
  return (
    <main className="component app">
      <ErrorBoundary msg="App does not work. Please retry later.">
        <Header className="header">
          {/* <Nav /> */}
          <AuthButton />
        </Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/user/:userId">
            <User />
          </ProtectedRoute>
          <ProtectedRoute exact path="/board/:boardId">
            <Board />
          </ProtectedRoute>
          <ProtectedRoute exact path="/issue/:issueId">
            <Issue />
          </ProtectedRoute>
          <ProtectedRoute exact path="/board/:boardId/issue">
            <NewIssue />
          </ProtectedRoute>
          <Route exact path="/feedback" component={Feedback} />
          <Redirect from="*" to="/" />
          {/* <Route
            path="*"
            render={() => (
              <p className="main error">
                <b>Error</b> 404
              </p>
            )}
          /> */}
        </Switch>
      </ErrorBoundary>
    </main>
  )
}

export default App
