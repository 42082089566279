import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import useFetch from "../../hooks/useFetch"
import useFetchReducer from "../../hooks/useFetchReducer"
import Page from "../../components/Page"
import { search as config } from "../../config"
import SearchBar from "../../components/SearchBar"
import List from "../../components/List"
import IssueListItem from "../../components/IssueListItem"
import Notification from "../../components/Notification"

export default function Board(props) {
  let { boardId } = useParams()

  let [{ data: board }, fetchBoard] = useFetch(
    `${process.env.REACT_APP_API_BASE}/boards/${boardId}`,
    null,
  )

  useEffect(() => {
    fetchBoard(`${process.env.REACT_APP_API_BASE}/boards/${boardId}`)
  }, [boardId, fetchBoard])

  let [searchState, setSearchState] = useState(
    config.filters.reduce(
      (a, c) => ({
        ...a,
        [c.name]: c.default,
      }),
      {
        sort: config.sort.default,
        term: "",
      },
    ),
  )

  let url = `${process.env.REACT_APP_API_BASE}/issues?board=${boardId}&label=${searchState.label}&priority=${searchState.priority}&sort=${searchState.sort}&status=${searchState.status}&title=${searchState.term}`

  const [
    { data: issues, isLoading, isSuccess, isError },
    fetchBoardIssues,
  ] = useFetchReducer(url, null)

  useEffect(() => {
    fetchBoardIssues(url)
  }, [url, fetchBoardIssues])

  return (
    <Page>
      <div>
        <p>Issues</p>
        <div className="level mb-6">
          <div className="level-left">
            {board ? (
              <h1 className="title">{board.title}</h1>
            ) : (
              <h1 className="title">Board</h1>
            )}
          </div>
          <div className="level-right">
            <Link to={`/board/${boardId}/issue`}>
              <button
                data-testid="newIssueButton"
                className="button is-primary is-light"
              >
                New issue
              </button>
            </Link>
          </div>
        </div>
        <SearchBar
          className="mb-4"
          config={config}
          state={searchState}
          setState={searchState => setSearchState(searchState)}
        />
        {isLoading ? <Notification text="Loading .." /> : null}
        {isSuccess && (
          <List
            className="mb-6"
            data={issues}
            render={issue => <IssueListItem key={issue.id} issue={issue} />}
          />
        )}
        {isError ? (
          <Notification className="is-danger is-light" text="Error" />
        ) : null}
      </div>
    </Page>
  )
}
