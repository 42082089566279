import { useState, useEffect, useContext } from "react"
import { UserContext } from "../context/UserContext"

export default function useFetch(initialUrl = "", initialData = null) {
  const { token } = useContext(UserContext)

  const [data, setData] = useState(initialData)
  const [url, setUrl] = useState(initialUrl)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        if (!response.ok) throw new Error("Network response was not ok")
        const json = await response.json()
        const data = json.data

        setData(data)
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [url, token])

  return [{ data, isLoading, isError }, setUrl]
}
