import React, { useReducer, useContext } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { UserContext } from "../../context/UserContext"
import useForm from "../../hooks/useForm"
import { validateComment } from "../../helpers/validators"
import { fetchReducer } from "../../helpers/reducers"
import { postComment } from "../../helpers/rest"
import Notification from "../Notification"
import avatar from "../../assets/avatar.svg"
import styles from "./CommentForm.module.css"

export default function CommentForm({
  issue: { id: issueId },
  user: { id: userId, name, image = avatar },
}) {
  const history = useHistory()
  const { token } = useContext(UserContext)

  const [comment, dispatch] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  })

  const submit = async ({ content }) => {
    try {
      dispatch({ type: "FETCH_INIT" })
      const response = await postComment({
        comment: {
          content: content.trim(),
          issueId: issueId,
          userId: userId,
        },
        token,
      })
      if (!response.ok) throw new Error(response.status)
      const { data: comment } = await response.json()
      dispatch({ type: "FETCH_SUCCESS", payload: comment })
      // history.go(0)
      history.push(`/user/${userId}`)
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE", payload: error })
    }
  }

  const { handleChange, handleSubmit, values, errors } = useForm(
    {
      content: "",
    },
    validateComment,
    submit,
  )

  return (
    <article className={`component media mt-6 mb-6 ${styles.media}`}>
      <figure className="media-left">
        <span className="image is-48x48">
          <img className={`is-rounded`} src={image || avatar} alt={name} />
        </span>
      </figure>
      <div className="media-content">
        <form onSubmit={handleSubmit} noValidate>
          <div className="field">
            <p className="control">
              <textarea
                className="textarea"
                name="content"
                value={values.content}
                onChange={handleChange}
                placeholder="Add a comment..."
                disabled={comment.isLoading}
              ></textarea>
            </p>
          </div>
          {errors.content && (
            <p className={`help is-danger mt-2 mb-4`}>{errors.content}</p>
          )}
          <div className="field">
            <span className="control">
              <button
                className={`button is-primary is-light ${
                  comment.isLoading ? "is-loading" : null
                }`}
                type="submit"
                disabled={comment.isLoading}
              >
                Post
              </button>
            </span>
          </div>
        </form>
        {comment.isError ? (
          <Notification className={`is-danger is-light mt-4`} text={`Error`} />
        ) : null}
      </div>
    </article>
  )
}

Comment.propTypes = {
  issue: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
