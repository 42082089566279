import { useState, useEffect } from "react"

export default function useForm(initValues = {}, validation, callback) {
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setErrors(validation(values))
    setIsSubmitting(true)
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
}
