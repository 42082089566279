import React, { useContext, useReducer } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import useForm from "../../hooks/useForm"
import { validateLogin } from "../../helpers/validators"
import { loginUser } from "../../helpers/rest"
import { fetchReducer } from "../../helpers/reducers"
import Page from "../../components/Page"
import Notification from "../../components/Notification"

export default function LoginForm(props) {
  const history = useHistory()
  const { user, setUser, setToken } = useContext(UserContext)

  const [state, dispatch] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  })

  const submit = async ({ email, password }) => {
    dispatch({ type: "FETCH_INIT" })
    try {
      const response = await loginUser({ email, password })

      if (!response.ok) throw new Error(response.status)
      const { data } = await response.json()

      dispatch({ type: "FETCH_SUCCESS", payload: data })

      // EVAL: Wait
      setToken(data.token)
      setUser(data.user)

      history.push(`/user/${data.user.id}`)
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE", payload: error })
    }
  }

  const { handleChange, handleSubmit, values, errors } = useForm(
    {
      email: "",
      password: "",
    },
    validateLogin,
    submit,
  )

  if (user) {
    return <Redirect to={`user/${user.id}`} />
  }

  return (
    <Page>
      <div>
        <form className={`component`} onSubmit={handleSubmit} noValidate>
          <div className={`field`}>
            <label className={`label`} htmlFor={`email`}>
              Email
            </label>
            <div className={`control has-icons-left`}>
              <input
                id={`email`}
                className={`input`}
                name={`email`}
                type={`email`}
                value={values.email}
                onChange={handleChange}
                disabled={state.isLoading}
              />
              <span className={`icon is-small is-left`}>
                <i className={`fas fa-envelope`}></i>
              </span>
              {errors.email && (
                <p data-testid="emailInvalid" className={`help is-danger`}>
                  {errors.email}
                </p>
              )}
            </div>
          </div>
          <div className={`field`}>
            <label className={`label`} htmlFor={`password`}>
              Password
            </label>
            <div className={`control has-icons-left`}>
              <input
                id={`password`}
                className={`input`}
                name={`password`}
                type={`password`}
                value={values.password}
                onChange={handleChange}
                disabled={state.isLoading}
              />
              <span className={`icon is-small is-left`}>
                <i className={`fas fa-key`}></i>
              </span>
              {errors.password && (
                <p data-testid="passwordInvalid" className={`help is-danger`}>
                  {errors.password}
                </p>
              )}
            </div>
          </div>
          <input
            id="submitButton"
            data-testid="submitButton"
            className={`button is-primary mt-2 mb-4
        ${state.isLoading ? "is-loading" : null}`}
            type="submit"
            disabled={state.isLoading}
            value="Login"
          />
        </form>
        {state.isError ? (
          <Notification
            className={`is-danger is-light`}
            text={`Error. Retry...`}
            // text={`${state.error}`}
          />
        ) : null}
      </div>
    </Page>
  )
}
