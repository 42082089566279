import React, { useState } from "react"
const UserContext = React.createContext()

function UserContextProvider(props) {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  return (
    <UserContext.Provider
      className={`component`}
      value={{ user, setUser, token, setToken }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContextProvider, UserContext }
