import React from "react"
import PropTypes from "prop-types"
import { search as searchConfig } from "../../config"
import Select from "../Select"

export default function SearchBar({
  config = searchConfig,
  state,
  setState,
  className,
  // onSearch,
}) {
  return (
    <div className={`component ${className}`}>
      {/* <div className="field is-grouped mb-1"> */}
      <div className={`control is-expanded has-icons-left mb-2`}>
        <span className={`icon is-small is-left`}>
          <i className={`fas fa-search`}></i>
        </span>
        <input
          className={`input`}
          type="text"
          placeholder={config.input.placeholder}
          value={state.term ? state.term : ""}
          onChange={event => {
            const { value } = event.target
            setState({
              ...state,
              term: value,
            })
          }}
        />
      </div>
      {/* <button className={`control button`} onClick={onSearch}>
          Search
        </button>
      </div> */}
      <div className={`level`}>
        <div className={`level-left`}>
          {config.filters.map(filter => (
            <Select
              className={`level-item`}
              name={filter.name}
              key={filter.name}
              options={filter.options}
              value={state[filter.name]}
              onChange={value =>
                setState(state => ({
                  ...state,
                  [filter.name]: value,
                }))
              }
            />
          ))}
        </div>
        <div className={`level-right`}>
          <Select
            className={`level-item`}
            name={config.sort.name}
            options={config.sort.options}
            value={state.sort}
            onChange={value =>
              setState(state => ({
                ...state,
                sort: value,
              }))
            }
          />
        </div>
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  config: PropTypes.object,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  className: PropTypes.string,
  // onSearch: PropTypes.func,
}
