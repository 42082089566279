import React, { useState, useReducer, useContext } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { UserContext } from "../../context/UserContext"
import useForm from "../../hooks/useForm"
import { validateIssue } from "../../helpers/validators"
import { fetchReducer } from "../../helpers/reducers"
import { postIssue } from "../../helpers/rest"
import Notification from "../../components/Notification"
import Select from "../../components/Select"
import avatar from "../../assets/avatar.svg"
import styles from "./IssueForm.module.css"

export default function IssueForm({
  board: { id: boardId },
  config,
  user: { id: userId, name, image = avatar },
}) {
  const history = useHistory()
  const { token } = useContext(UserContext)

  const [meta, setMeta] = useState(
    config.filters.reduce(
      (a, c) => ({
        ...a,
        [c.name]: c.options[1],
      }),
      {},
    ),
  )

  const [state, dispatch] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  })

  const submit = async ({ title, content }) => {
    const issue = {
      title: title.trim(),
      content: content.trim(),
      boardId: boardId,
      userId: userId,
    }
    for (let [key, value] of Object.entries(meta)) {
      issue[key] = value
    }
    try {
      dispatch({ type: "FETCH_INIT" })
      const response = await postIssue({ issue, token })
      if (!response.ok) throw new Error(response.status)
      const { data } = await response.json()
      dispatch({ type: "FETCH_SUCCESS", payload: data })
      history.push(`/issue/${data.id}`)
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE", payload: error })
    }
  }

  const { handleChange, handleSubmit, values, errors } = useForm(
    {
      title: "",
      content: "",
    },
    validateIssue,
    submit,
  )

  return (
    <article className={`component media my-6 py-5 ${styles.media}`}>
      <figure className="media-left">
        <div className="image is-48x48">
          <img className={`is-rounded`} src={image || avatar} alt={name} />
        </div>
      </figure>
      <div className="media-content">
        <form onSubmit={handleSubmit} noValidate>
          <div className="field">
            <div className="control">
              <input
                className="input"
                name="title"
                type="text"
                value={values.title}
                onChange={handleChange}
                placeholder="Add a title..."
                disabled={state.isLoading}
              />
            </div>
            {errors.title && <p className="help is-danger">{errors.title}</p>}
          </div>
          <div className="field">
            <div className="control">
              <textarea
                className="textarea"
                name="content"
                value={values.content}
                onChange={handleChange}
                placeholder="Add an issue..."
                disabled={state.isLoading}
              ></textarea>
            </div>
            {errors.content && (
              <p className="help is-danger mt-2 mb-4">{errors.content}</p>
            )}
          </div>
          <div className="field">
            <div className="control">
              <button
                data-testid="postIssueButton"
                className={`button is-primary is-light ${
                  state.isLoading ? "is-loading" : null
                }`}
                type="submit"
                disabled={state.isLoading}
              >
                Post
              </button>
            </div>
          </div>
        </form>
        {state.isError ? (
          <Notification className="is-danger is-light mt-4" text="Error" />
        ) : null}
      </div>
      <div className="media-right">
        {config.filters.map(filter => (
          <Select
            className="mb-4"
            style={{ display: "block", width: "120px" }}
            name={filter.name}
            key={filter.name}
            options={filter.options}
            value={meta[filter.name]}
            onChange={value =>
              setMeta(state => ({
                ...state,
                [filter.name]: value,
              }))
            }
          />
        ))}
      </div>
    </article>
  )
}

IssueForm.propTypes = {
  board: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
