import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import { search as config } from "../../config"
import Page from "../../components/Page"
import IssueForm from "../../components/IssueForm"

export default function NewIssue(props) {
  let { boardId } = useParams()
  const { user } = useContext(UserContext)
  return (
    <Page>
      <h1 className={`title`}>New Issue</h1>
      <IssueForm board={{ id: boardId }} config={config} user={user} />
    </Page>
  )
}
