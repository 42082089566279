import React from "react"
import Page from "../../components/Page"
import LoginForm from "../../components/LoginForm"

export default function Login(props) {
  return (
    <Page>
      <h1 className={`title has-text-centered`}>Login</h1>
      <LoginForm />
    </Page>
  )
}
