import React, { useEffect, useContext, useReducer } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import { fetchReducer } from "../../helpers/reducers"
import { getIssue } from "../../helpers/rest"
import Page from "../../components/Page"
import CommentListItem from "../../components/CommentListItem"
import CommentInput from "../../components/CommentForm"
import List from "../../components/List"
import Notification from "../../components/Notification"
import avatar from "../../assets/avatar.svg"

export default function Issue(props) {
  const { issueId } = useParams()
  const { user: currentUser, token } = useContext(UserContext)

  const [issue, dispatch] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  })

  useEffect(() => {
    const fetchData = async ({ issueId, token }) => {
      try {
        dispatch({ type: "FETCH_INIT" })
        const issueRes = await getIssue({ issueId, token })
        if (!issueRes.ok) throw new Error(issueRes.status)
        const { data: issue } = await issueRes.json()
        dispatch({ type: "FETCH_SUCCESS", payload: issue })
      } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: err })
      }
    }
    fetchData({ issueId, token })
  }, [issueId, token])

  return (
    <Page>
      <div>
        {issue.isError && <h1 className={`title`}>Issue</h1>}
        {issue.isSuccess && (
          <div className={`mx-2`}>
            <h1 className={`title`}>{issue.data.title}</h1>
            <span
              className={`tag is-rounded mr-4`}
            >{`label: ${issue.data.label}`}</span>
            <span
              className={`tag is-rounded mr-4`}
            >{`priority: ${issue.data.priority}`}</span>
            <span
              className={`tag is-rounded mr-4 mb-4`}
            >{`status: ${issue.data.status}`}</span>

            <article className={`media my-4 mx-2`}>
              <figure className="media-left">
                <p className="image is-48x48">
                  <img
                    className={`is-rounded`}
                    src={issue.data.user.image || avatar}
                    alt={issue.data.user.name || "User"}
                  />
                </p>
              </figure>
              <div className="media-content">
                <div className="content mt-2">
                  <span className={`is-size-7`}>
                    {`${issue.data.user.name} on ${new Date(
                      issue.data.createdAt,
                    ).toLocaleDateString()}`}
                  </span>
                  <p className={`has-text-justified my-4`}>
                    {issue.data.content}
                  </p>
                </div>
              </div>
            </article>

            {issue.data.comments.length > 0 && (
              <List
                data={issue.data.comments}
                render={comment => (
                  <CommentListItem key={comment.id} comment={comment} />
                )}
              />
            )}
            <hr />
            <CommentInput user={currentUser} issue={{ id: Number(issueId) }} />
          </div>
        )}
        {issue.isLoading ? <Notification text="Loading .." /> : null}
        {issue.isError ? (
          <Notification className={`is-danger is-light`} text="Error" />
        ) : null}
      </div>
    </Page>
  )
}
