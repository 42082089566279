import React, { useReducer } from "react"
import { feedbackReducer } from "../../../src/helpers/reducers"
import { useHistory } from "react-router-dom"

function QuestionScreen({ onClickGood, onClickBad, onClose }) {
  return (
    <div data-testid="question-screen">
      <div className="level is-mobile">
        <h2 className="level-left is-size-5">
          How do you feel about IssueHub?
        </h2>
        <button
          className="level-right delete"
          data-testid="close-button"
          title="close"
          type="button"
          onClick={onClose}
        />
      </div>
      <div className="buttons">
        <button
          className="button is-success is-light"
          data-testid="good-button"
          type="button"
          onClick={onClickGood}
        >
          Good
        </button>
        <button
          className="button is-danger is-light"
          data-testid="bad-button"
          type="button"
          onClick={onClickBad}
        >
          Bad
        </button>
      </div>
    </div>
  )
}

function FormScreen({ onSubmit, onClose }) {
  return (
    <form
      data-testid="form-screen"
      onSubmit={e => {
        e.preventDefault()
        const { response } = e.target.elements

        onSubmit({
          value: response,
        })
      }}
    >
      <div className="level is-mobile">
        <h2 className="level-left is-size-5">Please tell us more</h2>
        <button
          className="level-right delete"
          data-testid="close-button"
          title="close"
          type="button"
          onClick={onClose}
        />
      </div>
      <textarea
        className="textarea mb-4"
        data-testid="response-input"
        name="response"
        placeholder="I do not like..."
      />
      <button
        className="button is-primary is-light"
        data-testid="submit-button"
      >
        Submit
      </button>
    </form>
  )
}

function ThanksScreen({ onClose }) {
  return (
    <div data-testid="thanks-screen">
      <div className="level is-mobile">
        <h2 className="level-left">Thanks for your feedback.</h2>
        <button
          className="level-rigth delete"
          data-testid="close-button"
          title="close"
          type="button"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default function FeedbackCard() {
  const [state, send] = useReducer(feedbackReducer, "question")
  const history = useHistory()

  switch (state) {
    case "question":
      return (
        <QuestionScreen
          onClickGood={() => send({ type: "GOOD" })}
          onClickBad={() => send({ type: "BAD" })}
          onClose={() => send({ type: "CLOSE" })}
        />
      )
    case "form":
      return (
        <FormScreen
          onSubmit={value => send({ type: "SUBMIT", value })}
          onClose={() => send({ type: "CLOSE" })}
        />
      )
    case "thanks":
      return <ThanksScreen onClose={() => send({ type: "CLOSE" })} />
    case "closed":
      history.push("/")
      return null
    default:
      return null
  }
}
