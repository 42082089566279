import React, { useContext, useEffect, useReducer } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import { fetchReducer } from "../../helpers/reducers"
import { getUserBoards, getUserIssues } from "../../helpers/rest"
import Page from "../../components/Page"
import List from "../../components/List"
import IssueListItem from "../../components/IssueListItem"
import BoardListItem from "../../components/BoardListItem"
import Notification from "../../components/Notification"

export default function User(props) {
  const { userId } = useParams()
  const { user, token } = useContext(UserContext)

  const [boards, setBoards] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: "",
  })

  useEffect(() => {
    const fetchUserBoards = async ({ userId, token }) => {
      try {
        setBoards({ type: "FETCH_INIT" })
        const res = await getUserBoards({ userId, token })
        if (!res.ok) throw new Error(res.status)
        const { data: boards } = await res.json()
        setBoards({ type: "FETCH_SUCCESS", payload: boards })
      } catch (err) {
        setBoards({ type: "FETCH_FAILURE", payload: err })
      }
    }
    fetchUserBoards({ userId, token })
  }, [userId, token])

  const [issues, setIssues] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  })

  useEffect(() => {
    const fetchUserIssues = async ({ userId, token }) => {
      try {
        setIssues({ type: "FETCH_INIT" })
        const res = await getUserIssues({ userId, token })
        if (!res.ok) throw new Error(res.status)
        const { data: issues } = await res.json()
        setIssues({ type: "FETCH_SUCCESS", payload: issues })
      } catch (err) {
        setIssues({ type: "FETCH_FAILURE", payload: err })
      }
    }
    fetchUserIssues({ userId, token })
  }, [userId, token])

  return (
    <Page>
      <h1 data-testid="userNameHeading" className={`title has-text-centered`}>
        {user.name}
      </h1>
      <section id="user-boards">
        <h2 className={`is-size-4 mt-4 mb-4`} id="user-boards">
          Your Boards
        </h2>
        {boards.isLoading ? <Notification text="Loading .." /> : null}
        {boards.isSuccess && (
          <List
            style={{
              display: "flex",
              flexFlow: "row wrap",
            }}
            data={boards.data}
            render={board => <BoardListItem key={board.id} board={board} />}
          />
        )}
        {boards.isError ? (
          <Notification className={`is-danger is-light`} text="Error" />
        ) : null}
      </section>
      <section id="user-issues">
        <h2 className={`is-size-4 mt-4 mb-4`} id="user-issues">
          Your Issues
        </h2>
        {issues.isLoading ? <Notification text="Loading .." /> : null}
        {issues.isSuccess && (
          <List
            data={issues.data}
            render={issue => <IssueListItem key={issue.id} issue={issue} />}
          />
        )}
        {issues.isError ? (
          <Notification className={`is-danger is-light`} text="Error" />
        ) : null}
      </section>
    </Page>
  )
}
