import { useState, useEffect, useReducer, useContext } from "react"
import { UserContext } from "../context/UserContext"
import { fetchReducer } from "../helpers/reducers"

const useFetchReducer = (initialUrl = "", initialData = null) => {
  const { token } = useContext(UserContext)

  const [url, setUrl] = useState(initialUrl)

  const [state, dispatch] = useReducer(fetchReducer, {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: initialData,
    error: "",
  })

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" })

      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        if (!response.ok) throw new Error(response.status)
        const json = await response.json()
        const data = json.data
        dispatch({ type: "FETCH_SUCCESS", payload: data })
      } catch (error) {
        dispatch({ type: "FETCH_FAILURE", payload: error })
      }
    }

    fetchData()
  }, [url, token])

  return [state, setUrl]
}

export default useFetchReducer
