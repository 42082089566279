import React from "react"
import PropTypes from "prop-types"
export default function List(props) {
  if (props.data.length > 0) {
    return (
      <ul className={`${props.className}`} style={props.style}>
        {props.data.map(item => props.render(item))}
      </ul>
    )
  } else if (props.data.length < 1) {
    return (
      <p
        className={`notification is-light ${props.className}`}
        style={props.style}
      >
        Nothing found
      </p>
    )
  } else {
    return null
  }
}

List.propTypes = {
  data: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}
