const search = {
  filters: [
    {
      name: "label",
      options: ["all", "bug", "feature", "question"],
      default: "all",
    },
    {
      name: "priority",
      options: ["all", "low", "medium", "high"],
      default: "all",
    },
    {
      name: "status",
      options: ["all", "todo", "doing", "done"],
      default: "all",
    },
  ],
  sort: {
    name: "sort",
    options: ["new", "old"],
    default: "new",
  },
  input: {
    placeholder: "title",
  },
}

export { search }
