const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isIdle: false,
        isLoading: true,
        isSuccess: false,
        isError: false,
      }
    case "FETCH_SUCCESS":
      return {
        ...state,
        isIdle: false,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      }
    case "FETCH_FAILURE":
      return {
        ...state,
        isIdle: false,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

function feedbackReducer(state, action) {
  switch (state) {
    case "question":
      switch (action.type) {
        case "GOOD":
          return "thanks"
        case "BAD":
          return "form"
        case "CLOSE":
          return "closed"
        default:
          throw new Error(`Unhandled action type: ${action.type}`)
      }
    case "form":
      switch (action.type) {
        case "SUBMIT":
          return "thanks"
        case "CLOSE":
          return "closed"
        default:
          throw new Error(`Unhandled action type: ${action.type}`)
      }
    case "thanks":
      switch (action.type) {
        case "CLOSE":
          return "closed"
        default:
          throw new Error(`Unhandled action type: ${action.type}`)
      }
    default:
      throw new Error(`Unhandled state: ${state}`)
  }
}

export { fetchReducer, feedbackReducer }
