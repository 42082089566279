import React from "react"
import PropTypes from "prop-types"
import avatar from "../../assets/avatar.svg"
export default function CommentListItem({ comment }) {
  return (
    <article className="media my-4 mx-2">
      <figure className="media-left">
        <div className="image is-48x48">
          <img
            className="is-rounded"
            src={comment.user.image || avatar}
            alt="user"
          />
        </div>
      </figure>
      <div className="media-content">
        <div className="content mt-2">
          <span className={`is-size-7`}>{`${comment.user.name} at ${new Date(
            comment.createdAt,
          ).toLocaleDateString()}`}</span>
          <p className={`has-text-justified my-4`}>{comment.content}</p>
        </div>
      </div>
    </article>
  )
}

CommentListItem.propTypes = {
  comment: PropTypes.object.isRequired,
}
