const loginUser = ({ email, password }) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  }
  return fetch(`${process.env.REACT_APP_API_BASE}/login`, options)
}

const getUserBoards = ({ userId, token }) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return fetch(
    `${process.env.REACT_APP_API_BASE}/boards?user=${userId}`,
    options,
  )
}

const getUserIssues = ({ userId, token }) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return fetch(
    `${process.env.REACT_APP_API_BASE}/issues?user=${userId}`,
    options,
  )
}

const getIssue = ({ issueId, token }) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return fetch(`${process.env.REACT_APP_API_BASE}/issues/${issueId}`, options)
}

const postIssue = ({ issue, token }) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(issue),
  }
  return fetch(`${process.env.REACT_APP_API_BASE}/issues`, options)
}

const postComment = ({ comment, token }) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(comment),
  }
  return fetch(`${process.env.REACT_APP_API_BASE}/comments`, options)
}

export {
  loginUser,
  getIssue,
  postIssue,
  postComment,
  getUserBoards,
  getUserIssues,
}
