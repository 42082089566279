import React from "react"
import Page from "../../components/Page"
import FeedbackCard from "../../components/FeedbackCard"

export default function Feedback(props) {
  return (
    <Page>
      <div className={`modal is-active`}>
        <div className="modal-background" />
        <div className="modal-card box">
          <FeedbackCard />
        </div>
      </div>
    </Page>
  )
}
