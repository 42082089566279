import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import logo from "../../assets/logo.svg"
import styles from "./Header.module.css"

export default function Header({ children }) {
  return (
    <header className={`${styles.header} header`}>
      <Link to="/">
        <figure className="image is-64x64">
          <img className="is-rounded" src={logo} alt="IssueBoard" />
        </figure>
      </Link>
      {children}
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.node,
}
