import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import avatar from "../../assets/avatar.svg"

export default function AuthButton(props) {
  const { user } = useContext(UserContext)
  if (user) {
    return (
      <Link className={`component`} to={`/user/${user.id}`}>
        <figure className={`image is-48x48`}>
          <img
            data-testid="userImageNavButton"
            className="is-rounded"
            src={user.image || avatar}
            alt={user.name}
          />
        </figure>
      </Link>
    )
  } else {
    return (
      <Link
        data-testid="loginNavButton"
        className={`component button is-primary is-light`}
        to={`/login`}
      >
        Login
      </Link>
    )
  }
}
