import React from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import styles from "./IssueListItem.module.css"

export default function IssueListItem({ issue }) {
  const history = useHistory()
  return (
    <li
      className={`box ${styles.li} ${styles.pointer}`}
      onClick={() => history.push(`/issue/${issue.id}`)}
    >
      <span
        className={`${styles.priority} ${
          issue.priority === "high" ? "has-text-danger" : null
        }`}
      >
        {issue.priority === "high" ? (
          <i className="fas fa-volume-up"></i>
        ) : issue.priority === "medium" ? (
          <i className="fas fa-fire fa-volume-down"></i>
        ) : issue.priority === "low" ? (
          <i className="fas fa-fire fa-volume-off"></i>
        ) : null}
      </span>
      <span className={`${styles.title}`}>
        {issue.title.length > 50
          ? `${issue.title.substring(0, 50)}...`
          : issue.title}
      </span>
      <span>
        <i
          className={`tag is-rounded ${styles.label} ${
            issue.label === "bug" ? "is-danger" : null
          } is-light`}
        >
          {issue.label}
        </i>
      </span>
      <span className={`icon ${styles.status}`}>
        {issue.status === "todo" ? (
          <i className={`far fa-circle has-text-danger`}></i>
        ) : issue.status === "doing" ? (
          <i className={`fas fa-spinner has-text-warning`}></i>
        ) : issue.status === "done" ? (
          <i className={`far fa-check-circle has-text-success`}></i>
        ) : (
          issue.status
        )}
      </span>
    </li>
  )
}

IssueListItem.propTypes = {
  issue: PropTypes.object.isRequired,
}
