import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./ErrorBoundary.module.css"

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // console.error(`ErrorBoundary catched an error: ${error}`)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Sentry
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          data-testid="errorBoundaryMsg"
          className={`${styles.error} notification is-danger`}
        >
          <p>{this.props.msg}</p>
          <br />
          <button
            className={`${styles.button} button`}
            onClick={() => this.setState({ hasError: false })}
          >
            Reload
          </button>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  msg: "Error. Please retry.",
}

ErrorBoundary.propTypes = {
  msg: PropTypes.string,
  children: PropTypes.node.isRequired,
}
