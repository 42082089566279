import React from "react"
import ReactDOM from "react-dom"
import { UserContextProvider } from "./context/UserContext"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import "./assets/bulma.scss"
import * as Sentry from "@sentry/react"
import * as serviceWorker from "./serviceWorker"

if (process.env.REACT_APP_ERROR_TRACKING === "on") {
  Sentry.init({
    dsn:
      "https://4a047b65d1a54bd19b4d7179017bdb71@o385341.ingest.sentry.io/5369848",
    // ...
  })
}

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <Router>
        <App />
      </Router>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
