function validateLogin(values) {
  let errors = {}

  if (!values.email) {
    errors.email = "Email is required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  } else if (values.password.length < 8) {
    errors.password = "Too less characters"
  }
  return errors
}

function validateIssue(values) {
  let errors = {}

  if (!values.title) {
    errors.title = "Title is required"
  } else if (values.title.length < 1) {
    errors.title = "Too less characters"
  }

  if (!values.content) {
    errors.content = "Content is required"
  } else if (values.content.length < 1) {
    errors.content = "Too less characters"
  }
  return errors
}

function validateComment(values) {
  let errors = {}

  if (!values.content) {
    errors.content = "Content is required"
  } else if (values.content.length < 1) {
    errors.content = "Too less characters"
  }
  return errors
}

export { validateLogin, validateIssue, validateComment }
