import React from "react"
import PropTypes from "prop-types"

export default function Page(props) {
  return (
    <section className={`component`}>
      <div className={`container`}>
        <div className={`columns is-desktop`}>
          <div className={`column is-8 is-offset-2`}>{props.children}</div>
        </div>
      </div>
    </section>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
