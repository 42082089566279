import React from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import styles from "./BoardListItem.module.css"

export default function BoardListItem({ board }) {
  const history = useHistory()
  return (
    <li
      className={`box is-primary mb-2 ${styles.box}`}
      style={{
        color: "#fafafa",
        backgroundColor: "#2a363b",
      }}
      key={board.id.toString()}
      onClick={() => history.push(`/board/${board.id}`)}
    >
      {board.title.length > 20
        ? `${board.title.substring(0, 20)}...`
        : board.title}
    </li>
  )
}

BoardListItem.propTypes = {
  board: PropTypes.object.isRequired,
}
