import React, { useContext } from "react"
import PropTypes from "prop-types"
import { UserContext } from "../../context/UserContext"
import { Route, Redirect } from "react-router-dom"

export default function ProtectedRoute({ children, ...rest }) {
  const { user } = useContext(UserContext)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
